import React, { useMemo, useEffect, useState } from 'react';
import { Alert, Button, Flex, Typography } from 'antd';
import { useEvent } from '../features/events/hooks/useEvent';
import dayjs from 'dayjs';

interface UserInterviewBannerProps {
  userId: string | number;
  subscriptionPlan: string;
}

const STORAGE_KEY_PREFIX = 'user_interview_banner_click_';

export const UserInterviewBanner: React.FC<UserInterviewBannerProps> = ({ userId, subscriptionPlan }) => {
  const storageKey = `${STORAGE_KEY_PREFIX}${userId}`;
  const { track } = useEvent();
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    const lastClickDate = localStorage.getItem(storageKey);
    if (!lastClickDate) {
      setShouldShowBanner(true);
    }
  }, [userId]);

  const bannerVariant = useMemo(() => {
    const variant = Number(userId) % 3;
    return {
      number: variant,
      label: variant === 0 ? 'A' : variant === 1 ? 'B' : 'C',
      text:
        variant === 0
          ? "Not seeing the data you need? Let's chat and make your Dashboard work for you!"
          : variant === 1
          ? "Your Dashboard should work for you! Missing key insights? Let's talk!"
          : "Sneak peek: We're improving the Dashboard! Want to shape it? Let's talk!",
    };
  }, [userId]);

  const handleBannerClick = () => {
    localStorage.setItem(storageKey, dayjs().toISOString());
    setShouldShowBanner(false);

    track('Schedule User Interview Banner Clicked', {
      variant: bannerVariant.label,
      subscription_plan: subscriptionPlan,
    });
  };

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Alert
      type="success"
      message={
        <Flex justify="left" align="center" gap={16}>
          <Typography.Text strong>
            <span style={{ fontSize: 18 }}>👋</span> {bannerVariant.text}
          </Typography.Text>
          <Button
            type="primary"
            href="https://meetings-eu1.hubspot.com/anna-makovnikova"
            target="_blank"
            onClick={handleBannerClick}
          >
            Schedule call
          </Button>
        </Flex>
      }
      style={{ marginBottom: '-16px', marginTop: '16px', border: 'none', backgroundColor: '#ccf5c7', padding: 12 }}
    />
  );
};
